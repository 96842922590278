import React from 'react'
import './presentation.css'
import pierre2 from '../../assets/pierre2.jpg'


function Presentation() {
  return (
    <>
    <div className='presentation-page' id='presentation'>
        <div className='underline-title'>
        <h2 className='presentation-title'><span>02.</span>Présentation</h2>
        </div>
      <div className='presentation-container'>

        <div className="presentation-text">
            <h2 className='qsj-title'>Qui-suis je ?</h2>

            <p>Je me présente, Pierre Vandevelde actuellement en bachelor d'expertise digitale chez Wis Lille et bientôt titulaire d'une certification <strong> community manager RNCP 6. </strong><br></br>

            Je poursuis mes études chez <strong> ASTON BY SQLI située à Eura Technologie </strong> afin de passer le titre <strong>Directeur de projet informatique RNCP 7 (Équivalent bac +5) en septembre 2023. </strong> <br/>
            
            Etant passionné par la technologie et le management j'ai donc opté pour cette formation. 
            Je suis une personne motivée, ambitieuse et sportive.</p>

              <h2 className='recherche-title'>Mes ambitions futures ?</h2>

              <p>Je souhaiterai devenir <strong> chef de projet digital </strong> car je suis persuadé que c'est un métier fait pour moi étant donné les différentes expériences professionnelles qui m'ont permis de développer <strong> ma cohésion d'équipe, une gestion du stress, une optimisation des ressources disponibles et renforcer ma sociabilité.</strong> <br />
              J'ai aussi eu l'occasion de développer ma créativité lors de différentes missions qui m'ont été confiées et d'enrichir mes connaissances dans le développement web, le marketing digital et la communication.
              </p>

            <h2 className='recherche-title'>Ce que je recherche ?</h2>

            <p>Je suis actuellement à la recherche d'une opportunité d’alternance pour mettre en pratique mes connaissances et acquérir de l'expérience dans un environnement professionnel <strong> en septembre 2023 dans la gestion de projet.</strong> <br/> <br/>
              Une <strong> alternance au rythme de  3 semaines en entreprise et 1 semaine en formation.</strong><br/> <br/>
              Je suis impatient d'acquérir une expérience concrète et de relever de nouveaux défis dans le milieu passionnant du digital. <br/> <br/> 
              Si vous ou votre entreprise êtes à la recherche d'une personne motivée et qualifiée pour une alternance, n'hésitez pas à me contacter afin de prendre rendez-vous. 
              </p>


        </div>

        <div className="presentation-img">
            <img src={pierre2} alt="" />

<div className='container-cards'>
            <div class="cards">
    <figure class="card">
        <figcaption class="card_title">Sportif</figcaption>
    </figure>
</div>

<div class="cards">
    <figure class="card">
        <figcaption class="card_title">Curieux</figcaption>
    </figure>
</div>

<div class="cards">
    <figure class="card">
        <figcaption class="card_title">Patient</figcaption>
    </figure>
</div>
</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Presentation
