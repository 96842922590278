import React from 'react'
import './realisation.css'
import {FaLongArrowAltRight} from 'react-icons/fa'
import gayolle from '../../assets/gayolle.png'
import acrochetoi from '../../assets/acrochetoi.png'
import formamiante from '../../assets/formamiante.png'
import bikesiou from '../../assets/bikesiou.png'
import codepen from '../../assets/codepen.png'


const data = [
    {
    id: 1,
    image: gayolle,
    title: 'Domaine la Gayolle',
    demo: 'https://domaine-la-gayolle.com/',
    description: 'Création d\'un site web e-commerce dynamique avec contact.'
    },
    {
    id: 2,
    image: acrochetoi,
    title: 'Accroche Toi',
    demo: 'https://accroche-toi.fr/',
    description: 'Création d\'un site vitrine pour une entreprise de travaux acrobatiques dans le Var.'
    },
    {
      id: 3,
      image: formamiante,
      title: 'Formamiante',
      demo: 'https://formamiante.eu/',
      description: 'Création d\'un site vitrine pour une entreprise de désamiantage dans le Var'
    },
    {
      id: 4,
      image: bikesiou,
      title: 'Bike Siou Blanc',
      demo: 'https://sioublanc.fr/',
      description: 'Création de différentes animations sur Codepen pour me divertir, et les rendre accessibles à tous.'
    },
    {
      id: 5,
      image: codepen,
      title: 'Animations open source',
      demo: 'https://codepen.io/pierre597',
      description: 'Création de différentes animations sur Codepen pour me divertir, et les rendre accessibles à tous.'
    },
    
]

const Realisation = () => {
  return (
    <section id="realisation">
      <div className='title-portfolio'>
        <h2>
          <span>04.</span> 
          Réalisations
        </h2>
      </div>

    <div className="container portfolio__container">
      {
        data.map(({id, image, title, github, demo, description}) => {
          return(
            <article key={id} className='portfolio__item'>
            <div className="portfolio__item-image">
              <img src={image} alt={title} />
            </div>
            <h3>{title}</h3>
            <p>{description}</p>
            <div className='portfolio__item-cta'>
            <a href={demo} className='btn btn-primary' target='_blank'>Lien vers le site <FaLongArrowAltRight/></a>
            </div>
          </article>
          )
        })
      }
    </div>
  </section>
  )
}

export default Realisation