import React from 'react';
import Socials from '../socials/Socials';
import './header.css';
import pierre from '../../assets/pierre.png'
import cv from '../../assets/cv.pdf'
import {FaFileDownload} from 'react-icons/fa';


function Header() {
  return (
    <>
        <div className='header' id='header'>
            <div className='container-text'>
            <h1 className='head-1'>Bienvenue, sur le portfolio de,</h1>
            <h2 className='head-2'>Pierre VANDEVELDE.</h2>
            <h3 className='head-3'>Je recherche une alternance.</h3>
            <p className='p-header'>Je suis à la recherche d'une entreprise qui me permettra de travailler en collaboration avec des professionnels expérimentés, de contribuer à des projets passionnants et de continuer à apprendre à développer mes compétences en gestion de projets.
            

            </p>
            <a className="head-button" target='_blank' href={cv}> Télécharger mon CV <FaFileDownload/></a>
            </div>
            <div className='container-img'>
                <img className='head-img' src={pierre} alt="" />
            </div>
        </div>

        <Socials/>

      </>
  )
}

export default Header
